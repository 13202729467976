import styled from "styled-components";

import NewCardFooter from "../newCardFooter/NewCardFooter";
import NewCardHeaderAction from "../newCardHeaderAction/NewCardHeaderAction";
import CredentialBody from "../newCardBody/CredentialBody";

import Theme from "../theme/theme";

const NewCardContainer = styled.div`
	background-color: white;
	width: auto;
	border-radius: 0.3rem;
	font-family: Arial, Helvetica, sans-serif;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.backgroundColor};
	margin: 8px;
`;

const TagContainer = styled.div`
	padding: 0.3rem 0.8rem;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 0.5rem;
`;

const TagItem = styled.label`
	font-size: 0.7rem;
	padding: 0.2rem 0.8rem;
	border-radius: 1rem;
	background-color: ${Theme.colors.secondary};
	color: white;
`;

const NewCardHeader = styled.div`
	padding: ${({ issuerTags }) => (issuerTags ? "0rem" : "0.8rem")} 0.8rem;
	padding-top: 0.8rem;
	display: flex;
	justify-content: space-between;
	flex: 1;
	grid-gap: 0.35rem;
`;

const Divider = styled.div`
	border-bottom: 0.5px solid ${Theme.colors.gray};
`;

const NewCardHeaderLeftContent = styled.div`
	display: flex;
	gap: 0.3rem;
`;

const NewCardImageContainer = styled.div`
	height: 2.5rem;
	width: 2.5rem;
	flex-shrink: 0;
`;

const NewCardTitle = styled.div`
	font-weight: ${Theme.fontWeights.bold};
`;

const SecondaryTitle = styled.div`
	color: ${Theme.colors.grey};
	font-size: 0.8rem;
`;

const NewCardBodyContainer = styled.div`
	padding: 0.8rem;
`;

export const NewCard = ({
  title = "Catalog",
  imageUrl = "https://imgur.com/lPhPYVh.png",
  hideImage = false,
  id = "",
  secondaryTitle = "",
  headerActionContent = <NewCardHeaderAction />,
  bodyContent = <CredentialBody />,
  footerContent = <NewCardFooter />,
  titleIcon = null,
  backgroundColor = "white",
  issuerTags = [],
  showContentDivider = true,
}) => {
  if (imageUrl.includes("https://s3.ca-central-1.amazonaws.com")) {
    imageUrl = "https://ipwru.s3.amazonaws.com/prod/Example%20University/undefined-1734528576322.png";
    console.log("imageUrl updated: " + imageUrl);
  }
  else {
    console.log("imageUrl good: " + imageUrl);
  }
  return (
    <NewCardContainer backgroundColor={backgroundColor}>
      <NewCardHeader issuerTags={(issuerTags || []).length ? true : false}>
        <NewCardHeaderLeftContent>
          {imageUrl && !hideImage && (
            <NewCardImageContainer>
              <img src={imageUrl} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="Credential" />
            </NewCardImageContainer>
          )}
          <div>
            {title && (
              <NewCardTitle>
                {title}
                {titleIcon}
              </NewCardTitle>
            )}
            {secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
          </div>
        </NewCardHeaderLeftContent>
        {headerActionContent && <div style={{ flex: "none" }}>{headerActionContent}</div>}
      </NewCardHeader>
      {(issuerTags || []).length > 0 ? (
        <TagContainer>
          {issuerTags.map((tag) => (
            <TagItem>{tag}</TagItem>
          ))}
        </TagContainer>
      ) : null}
      {showContentDivider && <Divider />}
      {bodyContent && <NewCardBodyContainer>{bodyContent}</NewCardBodyContainer>}
      {footerContent}
    </NewCardContainer>
  )
};

export default NewCard;
